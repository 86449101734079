<template>
  <b-modal
    :id="id"
    ref="refModal"
    :title="title"
    size="lg"
    hide-footer
  >
    <validation-observer
      #default="{ handleSubmit }"
      ref="refFormObserver"
    >
      <b-form
        :disabled="loading"
        @submit.prevent="handleSubmit(onSubmit)"
        @reset.prevent="resetForm"
      >
        <b-row
          class="match-height"
        >
          <b-col>
            <div>
              <text-input
                id="xbu_LN"
                name="xbu_LN"
                :value.sync="xbu_LN"
                :get-validation-state="getValidationState"
                rules=""
                label="Фамилия"
                api=""
              />
              <text-input
                id="xbu_FN"
                name="xbu_FN"
                :value.sync="xbu_FN"
                :get-validation-state="getValidationState"
                rules=""
                label="Имя"
                api=""
              />
              <text-input
                id="xbu_MN"
                name="xbu_MN"
                :value.sync="xbu_MN"
                :get-validation-state="getValidationState"
                rules=""
                label="Отчество"
                api=""
              />
              <!-- <phone-input
                id="xbu_tel"
                name="xbu_tel"
                :value.sync="xbu_tel"
                :get-validation-state="getValidationState"
                rules=""
                label="Телефон"
                api=""
              />
              <text-input
                id="xbu_mail"
                name="xbu_mail"
                :value.sync="xbu_mail"
                :get-validation-state="getValidationState"
                rules=""
                label="E-mail"
                api=""
              /> -->
              <b-form-group
                label="Дата рождения"
                label-for="xbu_DateOfBrith"
              >
                <date-picker
                  id="xbu_DateOfBrith"
                  v-model="xbu_DateOfBrith"
                  class="w-100"
                  format="DD.MM.YYYY"
                  placeholder="дд.мм.гггг"
                />
              </b-form-group>
              <text-input
                id="xbu_town"
                name="xbu_town"
                :value.sync="xbu_town"
                :get-validation-state="getValidationState"
                rules=""
                label="Город"
                api=""
              />                                      </div>
          </b-col>
          <b-col>
            <div>
              <search-select-input
                id="xbu_buyerStruct"
                name="xbu_buyerStruct"
                :value.sync="xbu_buyerStruct"
                init-api="https://api.hoglabest.com/api/v1/json/lk/buyer/search/list/reg?q=--1"
                :get-option-label="option =&gt; option.query"
                :reduce="val =&gt; val.id_BuyerC"
                label="Кто пригласил"
                placeholder="Номер, ФИО, телефон, email"
                search-label="Номер, ФИО, телефон, email"
                api="https://api.hoglabest.com/api/v1/json/lk/buyer/search/list/reg"
              />
              <search-select-input
                id="xbu_buyer"
                name="xbu_buyer"
                :value.sync="xbu_buyer"
                :get-option-label="option =&gt; option.query"
                :reduce="val =&gt; val.id_BuyerC"
                label="Под кем стоит"
                placeholder="Номер, ФИО, телефон, email"
                search-label="Номер, ФИО, телефон, email"
                api="https://api.hoglabest.com/api/v1/json/lk/buyer/search/list/reg"
              />
              <b-form-group
                label="Ветка"
                label-for="xbu_branch"
              >
                <div class="d-flex">
                  <b-form-radio
                    id="xbu_branch"
                    v-model="xbu_branch"
                    name="Левая"
                    value="0"
                    class="mr-50"
                  >
                    Левая
                  </b-form-radio>
                  <b-form-radio
                    v-model="xbu_branch"
                    name="Правая"
                    value="1"
                  >
                    Правая
                  </b-form-radio>
                </div>
              </b-form-group>
              <select-input
                id="xbu_inpt"
                name="xbu_inpt"
                :value.sync="xbu_inpt"
                :get-option-label="option =&gt; option.text"
                :reduce="val =&gt; val.value"
                label="Тип входа"
                api="https://api.hoglabest.com/api/v1/json/lk/service/data/list/inptprog/reg"
              />
              <!-- <select-input
                id="xbu_wh"
                name="xbu_wh"
                :value.sync="xbu_wh"
                :get-option-label="option =&gt; option.text"
                :reduce="val =&gt; val.value"
                label="Офис обслуживания"
                api="https://api.hoglabest.com/api/v1/json/lk/service/data/list/office/reg"
              /> -->
            </div>
          </b-col>
        </b-row>
        <hr
          style="margin-left:-1.4rem;margin-right:-1.4rem;"
        >
        <div class="d-flex mt-2 justify-content-end">
          <b-button
            variant="flat-danger"
            class="mr-50"
            @click="hideModal()"
          >
            Отмена
          </b-button>
          <b-button
            variant="primary"
            type="submit"
            :disabled="loading"
          >
            <b-spinner
              v-if="loading"
              small
              class="mr-50"
            />
            <span>Добавить</span>
          </b-button>
        </div>
      </b-form>
    </validation-observer>
  </b-modal>
</template>
<script>
/* eslint-disable */
// prettier-ignore
import {
  BModal,
  BForm,
  BRow,
  BCol,
  BButton,
  BSpinner,
  BFormRadio,
  BFormGroup,
} from 'bootstrap-vue'
import { ValidationObserver } from 'vee-validate'
import formValidation from '@core/comp-functions/forms/form-validation'
import axios from '@axios'

import { ref } from '@vue/composition-api'
import { nextTick } from '@vue/composition-api'

import moment from 'moment'

import TextInput from '@core/components/input/text-input/TextInput.vue'
import PhoneInput from '@core/components/input/phone-input/PhoneInput.vue'
import DateInput from '@core/components/input/date-input/DateInput.vue'
import SelectInput from '@core/components/input/select-input/SelectInput.vue'
import SearchSelectInput from '@core/components/input/search-select-input/SearchSelectInput.vue'

export default {
  components: {
    BModal,
    BForm,
    BRow,
    BCol,
    ValidationObserver,
    BButton,
    BSpinner,
    BFormRadio,
    BFormGroup,
    TextInput,
    PhoneInput,
    DateInput,
    SearchSelectInput,
    SelectInput,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    sendApi: {
      type: String,
      required: true,
    },
    method: {
      type: String,
      required: true,
      default: () => 'post',
    },
    getApi: {
      type: String,
      required: true,
    },
    apiId: {
      type: [Object, String, Number, null],
      required: false,
    },
    refresh: {
      type: Boolean,
      required: false,
    },
  },
  setup(props, { root, emit }) {
    const refModal = ref(null)
    const xbu_LN = ref('')
    const xbu_FN = ref('')
    const xbu_MN = ref('')
    // const xbu_tel = ref('')
    // const xbu_mail = ref('')
    const xbu_DateOfBrith = ref('2021-12-21')
    const xbu_town = ref('')
    const xbu_buyerStruct = ref('0')
    const xbu_buyer = ref('0')
    const xbu_inpt = ref('3')
    // const xbu_wh = ref('1')
    const xbu_branch = ref('0')

    const resetData = () => {
      xbu_LN.value = ''
      xbu_FN.value = ''
      xbu_MN.value = ''
      // xbu_tel.value = ''
      // xbu_mail.value = ''
      xbu_DateOfBrith.value = ''
      xbu_town.value = ''
      xbu_buyerStruct.value = '0'
      xbu_buyer.value = '0'
      xbu_inpt.value = ''
      // xbu_wh.value = ''
      xbu_branch.value = ''
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetData)

    const hideModal = () => {
      resetForm()
      refModal.value.hide()

      emit('update:refresh', true)
      nextTick(() => {
        // reset refresh
        emit('update:refresh', false)
      })
    }

    if (props.getApi) {
      axios.get(props.getApi)
        .then(response => {
          const { data } = response.data
          xbu_LN.value = data['xbu_LN']
          xbu_FN.value = data['xbu_FN']
          xbu_MN.value = data['xbu_MN']
          // xbu_tel.value = data['xbu_tel']
          // xbu_mail.value = data['xbu_mail']
          xbu_DateOfBrith.value = data['xbu_DateOfBrith']
          xbu_town.value = data['xbu_town']
          xbu_buyerStruct.value = data['xbu_buyerStruct']
          xbu_buyer.value = data['xbu_buyer']
          xbu_inpt.value = data['xbu_inpt']
          // xbu_wh.value = data['xbu_wh']
          xbu_branch.value = data['xbu_branch']
        })
    }

    const loading = ref(false)
    const onSubmit = () => {
      loading.value = true
      const formData = {
        xbu_ln: xbu_LN.value,
        xbu_fn: xbu_FN.value,
        xbu_mn: xbu_MN.value,
        // xbu_tel: xbu_tel.value,
        // xbu_mail: xbu_mail.value,
        xbu_dateofbrith: xbu_DateOfBrith.value && moment(xbu_DateOfBrith.value).format('MM.DD.YYYY'),
        xbu_town: xbu_town.value,
        xbu_buyerstruct: xbu_buyerStruct.value.id_BuyerC || xbu_buyerStruct.value,
        xbu_buyer: xbu_buyer.value.id_BuyerC || xbu_buyer.value || '0',
        xbu_inpt: xbu_inpt.value,
        // xbu_wh: xbu_wh.value,
        xbu_branch: xbu_branch.value,
      }

      if (props.sendApi) {
        const url = props.apiId ? `${props.sendApi}/${props.apiId}` : props.sendApi
        if (props.method === 'post') {
          axios.post(url, formData)
            .then(response => {
              const { data } = response.data

              hideModal()

              root.$swal({
                icon: 'success',
                html: data.msg,
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })

              loading.value = false
            })
            .catch(err => {
              const { data } = err.response.data

              root.$swal({
                icon: 'error',
                html: data.msg,
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })
              loading.value = false
            })
        }

        if (props.method === 'put') {
          axios.put(url, formData)
            .then(response => {
              const { data } = response.data

              root.$swal({
                icon: 'success',
                text: data.msg,
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })

              loading.value = false
            })
            .catch(() => {
              loading.value = false
            })
        }
      }
    }

    return {
      refFormObserver,
      getValidationState,
      resetForm,
      onSubmit,
      loading,
      xbu_LN,
      xbu_FN,
      xbu_MN,
      // xbu_tel,
      // xbu_mail,
      xbu_DateOfBrith,
      xbu_town,
      xbu_buyerStruct,
      xbu_buyer,
      xbu_inpt,
      // xbu_wh,
      xbu_branch,
      refModal,
      hideModal,
    }
  },
}
</script>
